import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        statisticsData: null,
    },
    getters: {},
    mutations: {
        UPDATE_STATISTICS_DATA(state, val) {
            state.statisticsData = val;
        },

    },
    actions: {
        getStatistics({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/statistic')
                    .then(response => {
                        commit('UPDATE_STATISTICS_DATA', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
