import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        discounts: [],
        locations: [],
        services: [],
    },
    getters: {},
    mutations: {
        UPDATE_DISCOUNTS(state, val) {
            state.discounts = val;
        },
        UPDATE_LOCATIONS(state, val) {
            state.locations = val;
        },
        DELETE_DISCOUNT(state, val) {
            state.discounts = state.discounts.filter(discount => discount.id !== val);
        },
        ADD_DISCOUNT(state, val) {
            state.discounts.push(val);
        },
        UPDATE_SERVICES(state, val) {
            state.services = val;
        }
    },
    actions: {
        getDiscounts({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/discounts_by_center/' + payload)
                    .then(response => {
                        commit('UPDATE_DISCOUNTS', response.data.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getLocations({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/locations')
                    .then(response => {
                        commit('UPDATE_LOCATIONS', response.data.locations.map(obj => ({
                            id: obj.id,
                            name: obj.name
                        })));
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deleteDiscount({commit,state}, payload) {
            // save deleted discount into local variable and delete it from array

            let deletedDiscount = state.discounts.find(discount => discount.id === payload);
            commit('DELETE_DISCOUNT', payload);
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/discounts/delete/' + payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        // if error occurred, add deleted discount back to array
                        commit('ADD_DISCOUNT', deletedDiscount);
                        reject(error)
                    })
            })
        },
        getAllServices({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/services')
                    .then(response => {
                        commit('UPDATE_SERVICES', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        },
        addNewDiscount({commit},payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/discounts/add',payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        },
        getDiscountById({commit},payload){
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/discounts/get/'+payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        },
        editDiscount({commit},payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/discounts/update',payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        },
    },
}
