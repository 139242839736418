import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        services: [],
        categories: null,
    },
    getters: {},
    mutations: {
        UPDATE_SERVICES(state, val) {
            state.services = val;
        },
        UPDATE_CATEGORIES(state, val) {
            state.categories = val;
        },
    },
    actions: {
        getServices({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/services')
                    .then(response => {
                        commit('UPDATE_SERVICES', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getServicesCategories({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/services_category')
                    .then(response => {
                        commit('UPDATE_CATEGORIES', response.data.categories);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getSingleService({commit}, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/center/services/get/${id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        addNewService({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/services/add', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateService({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/services/update', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        switchServiceStatus({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/services/change_status', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        },
        deleteService({commit}, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/services/delete/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getAllLocations({commit}){
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/locations')
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        makeNewOrder({commit}, payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/points/add', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
