import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        profileInfo: {},
    },
    getters: {},
    mutations: {
        UPDATE_PROFILE_INFO(state, val) {
            state.profileInfo = val;
        },

    },
    actions: {
        getProfileInfo({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/profile')
                    .then(response => {
                        commit('UPDATE_PROFILE_INFO', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateProfileInfo({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/profile/update', payload)
                    .then(response => {
                        // commit('UPDATE_PROFILE_INFO', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
