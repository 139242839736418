import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        ratings: [],
    },
    getters: {},
    mutations: {
        UPDATE_RATINGS(state, val) {
            state.ratings = val;
        },
    },
    actions: {
        // Get All Ratings
        getRatings({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center-ratings' , {params: payload})
                    .then(response => {
                        commit('UPDATE_RATINGS', response.data.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    },
}
