import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        uploadImage({commit}, data) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append("file", data);
                axios
                    .post('/media/upload', formData)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            });
        },
    },
}
