import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from "@/store/auth";
import profile from "@/store/profile"
import media from "@/store/media";
import timeManagement from "@/store/timeManagement";
import services from "@/store/services";
import statistics from "@/store/statistics";
import locations from "@/store/locations";
import discounts from "@/store/discounts";
import rating from "@/store/rating";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        locations,
        auth,
        profile,
        media, statistics,
        timeManagement, services, discounts, rating,
    },
    strict: process.env.DEV,
})
