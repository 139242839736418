import Vue from 'vue'
import VueRouter from 'vue-router'
import global from "@/router/routes/global";
import center from "@/router/routes/center";
import {getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn} from "@/auth/utils";
import {canNavigate} from "@/libs/acl/routeProtection";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        ...center,
        ...global,
    ],
})


router.beforeEach((to, _, next) => {
    // Go directly to the login page
    if (to.name === "login") {
        return next();
    }
    const isLoggedIn = isUserLoggedIn()
    let canNavigateTo = canNavigate(to);
    if (!canNavigateTo) {
        // Redirect to login page if not logged in
        if (!isLoggedIn) return next({name: 'login'})

        // If logged in => not authorized
        return next({name: 'login'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
