import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        locations: {},
        singleLocation: {},
        categories: null,
    },
    getters: {},
    mutations: {
        UPDATE_LOCATIONS(state, val) {
            state.locations = val;
        },
        UPDATE_SINGLE_LOCATION(state, val) {
            state.singleLocation = val;
        },
        UPDATE_CATEGORIES(state, val) {
            state.categories = val;
        },
    },
    actions: {
        getLocations({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/locations')
                    .then(response => {
                        commit('UPDATE_LOCATIONS', [...response.data.locations]);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getLocationInfo({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/profile/' + payload)
                    .then(response => {
                        commit('UPDATE_SINGLE_LOCATION', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateLocationInfo({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/profile/update', payload)
                    .then(response => {
                        commit('UPDATE_SINGLE_LOCATION', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getLocationCategories({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/categories')
                    .then(response => {
                        commit('UPDATE_CATEGORIES', response.data["Center Categories"]);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        addNewLocation({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/profile/add', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getAddressFromCoordinates({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + payload.lat + "," + payload.lng +
                        "&key=AIzaSyCByPhePWZj75QB3c8-w3BMly83Gt-63Vw&result_type=country|administrative_area_level_1|administrative_area_level_2")
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
