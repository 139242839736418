export default [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('@/views/Home.vue'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
    // Locations routes
    {
        path: '/locations',
        name: 'location',
        component: () => import('@/views/locations/index'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
    {
        path: '/locations/:id?',
        name: 'location-edit',
        component: () => import('@/views/locations/locationProfile'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
    {
        path: '/center/time-management',
        name: 'time-management',
        component: () => import('@/views/time_management/index'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
    // Services routes
    {
        path: '/center/services',
        name: 'services',
        component: () => import('@/views/services/list'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
    {
        path: '/center/services/add',
        name: 'services-add',
        component: () => import('@/views/services/add'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
    {
        path: '/center/services/make-order',
        name: 'services-make-order',
        component: () => import('@/views/services/make_order.vue'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
//     Discounts routes
    {
        path: '/center/discounts',
        name: 'discounts',
        component: () => import('@/views/discounts/index.vue'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
    {
        path: '/center/discounts/add',
        name: 'discounts-add',
        component: () => import('@/views/discounts/add.vue'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
    {
        path: '/center/discounts/edit/:id',
        name: 'discounts-edit',
        component: () => import('@/views/discounts/edit.vue'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
    //     Rating routes
    {
        path: '/center/ratings',
        name: 'ratings',
        component: () => import('@/views/rating/list.vue'),
        meta: {
            resource: 'center',
            action: 'read',
        },
    },
]