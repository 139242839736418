import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: 'https://dash.marid.com/api',
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
})


// Request interceptor for API calls
axiosIns.interceptors.request.use(
    async config => {
        // Remove Authorization header for Google Maps requests
        if (config.url.includes("maps.googleapis.com")) {
            delete config.headers["Authorization"];
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });


Vue.prototype.$http = axiosIns


export default axiosIns
