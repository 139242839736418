import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        workingHours: {},
    },
    getters: {},
    mutations: {
        UPDATE_WORKING_HOURS(state, val) {
            state.workingHours = val;
        },

    },
    actions: {
        getWorkingHours({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/times')
                    .then(response => {
                        commit('UPDATE_WORKING_HOURS', response.data);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        addNewTime({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/times/add', data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateTime({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/center/times/update', data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deleteTime({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/center/times/delete/'+ data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
